import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "app-provider";
import { Formik, Form, Field, FieldArray } from "formik";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Button } from "semantic-ui-react";
import {
  ListForm,
  InfoPopup,
  Loading,
  DictionaryOptions,
  DictionaryList,
} from "components/ui";
import { CompanyProfileContext } from "components/company-profile";
import * as Yup from "yup";
import api from "api";
import ExcludedHcpcsField from "./excluded-hcpcs-field";
import CapitatedInsurancesField from "./capitated-insurances-field";
import { toast } from "react-toastify";

const ResupplyImporterForm = () => {
  const companyFormOptions = useContext(CompanyProfileContext);
  const { header_options, form_of_contacts, inactivation_reasons } =
    useContext(AppContext);
  const { companyId } = useParams();
  const queryClient = useQueryClient();

  const companyImportSettings = useQuery(
    [`import-settings`, companyId, "Resupply"],
    async () => {
      const response = await api.get(
        `/api/import-settings/${companyId}/Resupply`,
      );
      return response.data;
    },
  );

  const updateImportSettings = useMutation(
    async (values) => {
      await api.put(`/api/import-settings/${companyId}/Resupply`, values);
      return values.companyId;
    },
    {
      onSuccess: (companyId) => {
        toast.success("Company Import Settings Updated");
        queryClient.invalidateQueries([
          "import-settings",
          companyId,
          "Resupply",
        ]);
      },
    },
  );

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {(companyImportSettings.isFetching || updateImportSettings.isLoading) && (
        <Loading />
      )}
      <Formik
        initialValues={{
          uses_headers: companyImportSettings.data?.uses_headers ?? true,
          require_dob: companyImportSettings.data?.require_dob ?? false,
          filename_constraint: companyImportSettings.data?.filename_constraint,
          dictionary: companyImportSettings.data?.dictionary ?? [],
          excluded_insurances:
            companyImportSettings.data?.excluded_insurances ?? [],
          excluded_branches:
            companyImportSettings.data?.excluded_branches ?? [],
          capitated_insurances:
            companyImportSettings.data?.capitated_insurances ?? [],
          medicare_insurances:
            companyImportSettings.data?.medicare_insurances ?? [],
          use_payor_id: companyImportSettings.data?.use_payor_id ?? false,
          demographicsOnlyUpdateExisting:
            companyImportSettings.data?.demographicsOnlyUpdateExisting ?? false,
          requre_dob: companyImportSettings.data?.requre_dob ?? false,
          allow_patient_branch_changes:
            companyImportSettings.data?.allow_patient_branch_changes ?? true,
          allow_address_updates:
            companyImportSettings.data?.allow_address_updates ?? true,
          inactive_for_bad_contact:
            companyImportSettings.data?.inactive_for_bad_contact ?? true,
          preferred_foc: companyImportSettings.data?.preferred_foc ?? [],
          excluded_hcpcs: companyImportSettings.data?.excluded_hcpcs ?? [],
        }}
        validationSchema={Yup.object().shape({
          dictionary: Yup.array().of(
            Yup.object({
              header: Yup.string().required("Required"),
            }),
          ),
        })}
        onSubmit={(values) => {
          updateImportSettings.mutate({
            ...values,
            dictionary: values.dictionary.map((x, index) => ({
              ...x,
              priority: index + 1,
            })),
            companyId,
          });
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray
              name="dictionary"
              render={(arrayHelpers) => (
                <div
                  style={{
                    display: "grid",
                    padding: "0px 50px",
                    fontSize: "1.2em",
                    gridTemplateColumns: "1fr 2fr",
                    minHeight: 500,
                  }}
                >
                  <DictionaryOptions
                    dictionary={values.dictionary}
                    options={header_options.resupply}
                    arrayHelpers={arrayHelpers}
                  />
                  <div>
                    <div
                      style={{
                        padding: 30,
                        display: "grid",
                        gridGap: 30,
                        gridTemplateColumns: "1fr auto auto",
                      }}
                    >
                      <label style={{ display: "flex", padding: 10 }}>
                        <Field type="checkbox" name="uses_headers" />
                        <span style={{ padding: "0px 20px" }}>
                          Uses Headers
                        </span>
                        <InfoPopup>
                          Import File has Headers, if not use column letters to
                          identiify which column in the file matches up with
                          data point
                        </InfoPopup>
                      </label>
                      <Button
                        type="button"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Reset
                      </Button>
                      <Button primary>Save</Button>
                    </div>
                    <DictionaryList
                      dictionary={values.dictionary}
                      options={header_options.resupply}
                      arrayHelpers={arrayHelpers}
                    />
                  </div>
                </div>
              )}
            />
            <div
              style={{
                display: "grid",
                margin: 20,
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <ListForm
                fieldName="excluded_insurances"
                options={companyFormOptions?.insurances}
                setFieldValue={setFieldValue}
                label="Excluded Insurances"
                list={values?.excluded_insurances}
              />
              <CapitatedInsurancesField
                setFieldValue={setFieldValue}
                capitated_insurances={values.capitated_insurances}
                insurances={companyFormOptions?.insurances}
              />
              <ListForm
                fieldName="excluded_branches"
                options={companyFormOptions?.branches}
                setFieldValue={setFieldValue}
                label="Excluded Branches"
                list={values?.excluded_branches}
              />
            </div>
            <div
              style={{
                fontSize: "1.5em",
                margin: 20,
                padding: 20,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div style={{ fontSize: "20px" }}>
                <ListForm
                  fieldName="preferred_foc"
                  options={form_of_contacts.map((x) => ({
                    id: x.value,
                    name: x.text,
                  }))}
                  setFieldValue={setFieldValue}
                  label={
                    <span>
                      Preferred Form Of Contact
                      <InfoPopup>
                        Bad Phone Number/Contact info will allow updating
                        patients form of contact if we get contact info from
                        file down the road
                      </InfoPopup>
                    </span>
                  }
                  list={values?.preferred_foc}
                  sortable
                />
              </div>

              <ExcludedHcpcsField
                setFieldValue={setFieldValue}
                excluded_hcpcs={values.excluded_hcpcs}
                inactivation_reasons={inactivation_reasons}
              />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "flex-end",
                  padding: 20,
                  border: "1px solid #ccc",
                }}
              >
                {/* <label style={{ display: "flex", padding: 10 }}> */}
                {/*   <Field type="checkbox" name="auto_create_insurances" /> */}
                {/*   <span style={{ paddingLeft: 10 }}> */}
                {/*     Auto Create Insurances */}
                {/*   </span> */}
                {/* </label> */}
                <label style={{ display: "flex", padding: 10 }}>
                  <Field type="checkbox" name="use_payor_id" />
                  <span style={{ paddingLeft: 10 }}>
                    Use Insurance Payor Ids
                  </span>
                </label>
                <div style={{ display: "flex" }}>
                  <label style={{ display: "flex", padding: 10 }}>
                    <Field type="checkbox" name="require_dob" />
                    <span style={{ paddingLeft: 10 }}>
                      Require Patient Date Of Birth
                      <InfoPopup>
                        New Patients will be excluded if they do not have a date
                        of birth in file
                      </InfoPopup>
                    </span>
                  </label>
                  <label style={{ display: "flex", padding: 10 }}>
                      <Field
                        type="checkbox"
                        name="allow_patient_branch_changes"
                      />
                      <span style={{ paddingLeft: 10 }}>
                        Allow Patient Branch Changes
                        <InfoPopup>
                          Existing Patients will get their branch updated if the
                          branch in the file is different than the patients
                          current branch
                        </InfoPopup>
                      </span>
                    </label>
                  <label style={{ display: "flex", padding: 10 }}>
                      <Field
                        type="checkbox"
                        name="allow_address_updates"
                      />
                      <span style={{ paddingLeft: 10 }}>
                        Allow Address Updates
                        <InfoPopup>
                          Allow existing patients to get their address updated if order is not an open order.
                          if we have no address on file for a patient we will continue to update patients address regardless of this setting
                        </InfoPopup>
                      </span>
                    </label>
                </div>
                <label style={{ display: "flex", padding: 10 }}>
                    <Field
                      type="checkbox"
                      name="inactive_for_bad_contact"
                    />
                    <span style={{ paddingLeft: 10 }}>
                      Inactivate Patient For Bad Contact
                    </span>
                  </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "1.4em",
                margin: 30,
              }}
            >
              <label>Import notes/documentation</label>
              <Field as="textarea" name="notes" />
            </div>
            <div style={{ margin: 10 }}>
              <label style={{ display: "flex", padding: 10 }}>
                <span style={{ padding: "0px 20px" }}>
                  File Name Constraint
                </span>
                <Field type="text" name="filename_constraint" />
                <InfoPopup>
                  File Name constraint, checks for partial match via regular
                  expression
                </InfoPopup>
              </label>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResupplyImporterForm;
